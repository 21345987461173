import { ApiUrl, ApiUrlAdmin } from "../common/Config";
import { postAdmin,post,get,getAdmin,postFormData } from "../common/Helper";

export const getCourses = async (token) =>{
    let url = ApiUrl + "book/questionSubCourseTopicCourses"
    return await get(url,token)
}
export const getQuestionById = async (token,examType,courseId,order) =>{
    let url = ApiUrl + `book/GetQuestionForSubCourseTopicSelection?examType=${examType}&courseId=${courseId}&order=${order}`
    return await get(url,token)
}
export const nestedTitles = async (token) =>{
    let url = ApiUrl + "Book/getpanelnestedtitles"
    return await get(url,token)
}

export const updateQuestion = async (token,data) => {
    let url = ApiUrl + `book/UpdateQuestionSubCourseTopicId`
    return await post(url,data,token)
}
export const getCoursesForVideoSolution = async (token) =>{
    let url = ApiUrl + "book/getQuestionAnswerVideoLogRatio"
    return await get(url,token)
}