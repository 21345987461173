import React from "react";
import ReactDOM from "react-dom";
import "./LogMessageModal.css";

const LogDetailModal = ({ isOpen, onClose, log }) => {
  if (!isOpen || !log) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          Close
        </button>
        
        {/* Display the entire log or just the full message. Here we show all properties */}
        <h3>Log Details</h3>
        <div><strong>ID:</strong> {log.Id}</div>
        <div><strong>Date:</strong> {log.LogDate}</div>
        <div><strong>Application:</strong> {log.Application}</div>
        
        <div style={{ marginTop: "1em" }}>
          <strong>Message:</strong>
          <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
            {log.Message}
          </pre>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default LogDetailModal;
