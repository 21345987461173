import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  font-size: 22px;
  color: gray;
`;

const Roasts = [
  "What are you waiting for? A divine revelation? SEARCH. 🤨",
  "Bro, this ain't Netflix. Click something. 🎬",
  "If you don't search soon, I'm gonna start logging YOU. 👀",
  "You think the logs will search themselves? 🤡",
  "Frontend devs be like: 'Where are the logs?' WITHOUT SEARCHING. 🤦‍♂️",
  "Logs? Oh, you mean those things you have to SEARCH for? 😏",
  "Ah yes, staring at the screen. That'll fix everything. 🙄",
  "Still waiting for logs to magically appear? Good luck with that. 🪄",
  "At this rate, you’ll debug faster by reading tea leaves. 🍵",
  "404: Developer effort not found. 🔍",
];

const FirstTimeScreen = (isSearched) => {
  const [roast, setRoast] = useState("");

  useEffect(() => {
    setRoast(Roasts[Math.floor(Math.random() * Roasts.length)]);
  }, []);

  return (
    <Container>
    {!isSearched.isSearched &&  <p>{roast}</p>}
    </Container>
  );
};

export default FirstTimeScreen;
