import React, { useState } from "react";
import SideBar from "../../components/SideBar";
import { ToastContainer, toast } from "react-toastify";
import { Components } from "../../assets/Dimensions";
import RangeDatePicker from "./components/datePicker";
import LogKeysSelect from "./components/selectKeys";
import SearchInput from "./components/searchInput";
import { getLogs } from "../../api/AppLogs/appLogsApis";
import useAuth from "../../hooks/useAuth";
import LogKeys from "../../enums/LogKeys";
import LogsTable from "./components/logTable";
import ResponseStatus from "../../util/enums";
import { useMediaQuery } from "react-responsive";
import ExportCsvButton from "./components/ExportCsvButton";
import Loader from "@material-ui/core/CircularProgress"
import FirstTimeScreen from "./components/firstTimeText";

const AppLogs = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })
    const [nav, setNav] = useState(false);
    const { user, userAdminToken } = useAuth();
    const [logs,setLogs] =useState(null)
  const [searchValue, setSearchValue] = useState("");
  const [selectedKey, setSelectedKey] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [dateRange, setDateRange] = useState({
    minDate: new Date(),
    maxDate: new Date(),
  });
  const styles = {
    container: {
        //state e bool değer ekle menü butonuna basıldığında css 
        //içinde container a margin eklesin hemde navı açsın

        marginLeft: isTabletOrMobile ? 0 : Components.SideBarWidth + 15,
        paddingTop: 20,
        marginRight: 30,
        marginBottom: 20

    },
    table: {
        marginTop: "1%"
    },
    tableButtons:{
      display:"flex",
      justifyContent:"space-between",
      alignContent: "center"
    },
    SearchFilters:{
      display:"flex",
      justifyContent:"space-around",
      alignItems: "flex-end"
    },
    SearchButton:{
      borderRadius:"8px",
      height:"45px",
      borderWidth:"1px",
      paddingLeft:"12px",
      paddingRight:"12px",
      paddingTop:"14px",
      paddingBottom:"14px"
    },
    SearchFiltersMobil:{
      display:"flex",
      flexDirection:"column",
      gap:"10px"
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleLogKeySelect = (value) => {
    setSelectedKey(value);
  };

  const handleDateRangeChange = ({ minDate, maxDate }) => {
    setDateRange({ minDate, maxDate });
  };

// Helper function to format a date as "yyyy/MM/dd HH:mm:ss.SSS"
const formatDate = (date) => {
    if (!date) return "";
  
    const pad = (num, size = 2) => num.toString().padStart(size, "0");
    
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    const milliseconds = pad(date.getMilliseconds(), 3); // pad to 3 digits
  
    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  };
  

  // Adjusts a given date to 3 AM
  const adjustDateToThreeAM = (date) => {
    if (!date) return date;
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const handleApiRequest = async () => {
    setIsSearched(true)
    setLoader(true)
    setLogs(null)
    const payload = {
      minDate: formatDate(adjustDateToThreeAM(dateRange.minDate)), // setting the min date time to 3 am fixed
      maxDate: formatDate(dateRange.maxDate),
      key: selectedKey,
      searchValue: searchValue,
    };
    console.log("payload,user.Token",
    payload,user.Token);

    // const getLogsReq= await getLogs(payload,userAdminToken)
    const getLogsReq= await getLogs(payload,user.Token)
    if(getLogsReq.data.ResponseStatus == ResponseStatus.Success){
        setLogs(getLogsReq.data.ResponseData)
    }else if(getLogsReq.data.ResponseStatus == ResponseStatus.Error){
        console.log("getLogsReq.data.ResponseMessage",getLogsReq.data.ResponseMessage);
        toast.warn(getLogsReq.data.ResponseMessage)
    }
    setLoader(false)
    console.log("getLogsReq",getLogsReq);

    console.log("API Request Payload:", payload);
    // Insert your API request logic here
  };

  return (
    <div>
   {isTabletOrMobile ?
                <div>
                    <span style={{ "position": "absolute", top: 10, right: 10 }} onClick={() => (setNav(!nav))}>menu</span>
                    {nav && <SideBar />}

                </div>
                : <SideBar />}

      <ToastContainer />
      <div style={styles.container}>
      {loader&&<div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Loader size={100} />
          </div>}
        <div style={!isTabletOrMobile? styles.SearchFilters: styles.SearchFiltersMobil}>
          <SearchInput value={searchValue} onChange={handleSearchInputChange} />
          <LogKeysSelect
            selectedKey={selectedKey}
            onSelect={handleLogKeySelect}
          />
          <RangeDatePicker onChangeDateRange={handleDateRangeChange} />
          <button style={styles.SearchButton} disabled= { searchValue.length<3 && !selectedKey}  onClick={handleApiRequest}>
            Search
          </button>
        </div>
           {!logs && <FirstTimeScreen isSearched={isSearched}/>}
       {logs && <div style={styles.table}>
        <div style={styles.tableButtons}>
        <ExportCsvButton logs={logs}/>
        <span>Count: {logs.length-1}</span>
          </div>
        <LogsTable logs={logs}/>
        </div>}
      </div>
    </div>
  );
};

export default AppLogs;
