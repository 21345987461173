import React, { useEffect, useState } from "react";
import Toggle from "../../scenes/questionVideoSolution/components/toggle/toggle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import ExamType from "../../enums/ExamType";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "./subcourseStyle.css"

const SubCourses = ({ nestedTitlesData, CourseId, examTypeId,getSelectedSubTopic,questionDataExamType,questionDataCourseId,previousQuestion,nextQuestion,questionOrder,selectedSubTopic }) => {

    const [selectedTopic, setSelectedTopic] = useState(null);
    const [selectedCourseData, setSelectedCourseData] = useState(null);
    const [selectedCourseId, setSelectedCourseId] = useState(CourseId);
    const [checkedItems, setCheckedItems] = useState(null);
    const [selectedNestedTitlesData, setSelectedNestedTitlesData] =
    useState(nestedTitlesData);
    console.log("questionDataCourseId",{questionDataCourseId,selectedCourseId})
  const [selectedExamType, setSelectedExamType] = useState(examTypeId);

  const handleItemClick = (checkboxId) => {

    if (checkedItems) {
      if (checkedItems == checkboxId) {
        setCheckedItems(null);
      } else {
        setCheckedItems(checkboxId);
      }
    } else {
      setCheckedItems(checkboxId);
    }
  };

  console.log("nestedTitles",{nestedTitlesData,questionDataCourseId,selectedCourseId})

  useEffect(() => {
    if(selectedSubTopic){
      
    }
  },[])

  useEffect(() => {
   
      console.log("checkedİtems", checkedItems);
      getSelectedSubTopic(checkedItems)
  
  }, [checkedItems]);

  const getCourseNamesFromExamType = () => {};
  const getExamTypeName = (value) => {
    for (const key in ExamType) {
      if (ExamType[key] === value) {
        return key;
      }
    }
    return null; // Return null if the value is not found
  };

  const getSelectedNestedData = (id) => {
    const selectedNestedTitle = selectedNestedTitlesData.Courses.find(
      (item) => item.Id == id
    );

    return setSelectedCourseData(selectedNestedTitle);
  };

  const changeSelectedExamType = (id) => {
    setSelectedExamType(id);
    setSelectedNestedTitlesData(
      JSON.parse(localStorage.getItem(getExamTypeName(id)))
    );
  };

  const changeCourse = (courseId) => {
    setSelectedCourseId(courseId);
  };

  useEffect(() => {
    getSelectedNestedData(selectedCourseId);
  }, [selectedCourseId, selectedNestedTitlesData]);

  return (
    <div style={{overflow:"hidden"}}>
 <div  className="buttonContainer">
          <button
            disabled={questionOrder == 1}
            onClick={() => {
              setSelectedTopic(null);
              setCheckedItems(null);
              previousQuestion();
            }}
            className="prevButton"
          >
            <ArrowBackIcon />
          </button>
          <button
            className="nextButton"
            onClick={() => {
              setSelectedTopic(null);
              setCheckedItems(null);
              nextQuestion();
            }}
          >
            <ArrowForwardIcon />
          </button>
        </div>
    <div style={{ width: "100%",overflow:"auto" }}>
      {/* {checkedItems && (<div style={{backgroundColor:"green",color:"white"}}>
        {checkedItems.NewSubCourseTopicName}
      </div>)} */}
      {/* <div style={{zIndex:200,position:"fixed",marginTop:"-50px",display:"flex",flexDirection:"row",justifyContent:"space-between"}}> */}

     
       
    

      {/* </div> */}
      <div
        style={{
          width: "100%",
          //maxHeight:window.screen.height
        }}
      >
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 10,
              margin: 10,
            }}
          >
            {selectedExamType !== 3 && selectedExamType !== 4 &&(
              <Toggle
                labels={["TYT", "AYT"]}
                values={[ExamType.TYT, ExamType.AYT]}
                initialValue={selectedExamType}
                onChange={(value) => {
                  changeSelectedExamType(value);
                }}
                isFromTopicSelection
                questionDataExamType={questionDataExamType}
              />
            )}
            {/* kpss kontrolü eklenmesinin bu soruların couse nameleri aslında genel olduğu için buyüzden gösterimde yanlış course name altında olmasından dolayı  */}
            <div>
              <select
                style={{
                  backgroundColor:
                  selectedExamType === 4 ?  "white"  : questionDataCourseId == selectedCourseId ? "white" : "red",
                  color:
                  selectedExamType === 4 ?  "black"  : questionDataCourseId == selectedCourseId
                      ? "black"
                      : "white",
                }}
                onChange={(e) => changeCourse(e.target.value)}
              >
                <option value={null}>Ders Seçiniz</option>
                {selectedNestedTitlesData.Courses.map((item, index) => (
                  <option
                    value={item.Id}
                    selected={item.Id == selectedCourseId}
                    key={index}
                  >
                    {item.CourseName}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {selectedCourseData && (
            <div style={{ height: window.screen.height / 2 }}>
              <div>
                {selectedCourseData.CourseTopics.map((topic) => (
                  <div
                    style={{
                      padding: 10,
                      borderWidth: "2px",
                      border: 1,
                      borderRadius: 8,
                      borderStyle: "solid",
                      marginBottom: 10,
                    }}
                    key={topic.CourseTopicUniqueId}
                  >
                    <div
                      onClick={() => {
                        // Toggle the selected topic
                        setSelectedTopic(
                          selectedTopic === topic ? null : topic
                        );
                      }}
                    >
                      {topic.NewCourseTopicName}
                    </div>

                    {selectedTopic === topic && (
                      <div style={{ margin: 5 }}>
                        {topic.SubCourseTopics.map((subTopic) => (
                          <div
                            style={{
                              padding: 10,
                              borderWidth: "2px",
                              border: 1,
                              borderRadius: 8,
                              borderStyle: "solid",
                              marginBottom: 10,
                              // backgroundColor: checkedItems[topic.name].includes(subTopic.name) ? "lightblue" : "white", // Change background color based on checkbox state
                            }}
                            key={subTopic.SubCourseTopicUniqueId}
                          >
                            <label style={{ display: "block" }}>
                              <input
                                type="checkbox"
                                name={topic.NewCourseTopicName}
                                value={subTopic.SubCourseTopicUniqueId}
                                checked={checkedItems == subTopic}
                                onChange={() => {
                                  handleItemClick(subTopic);
                                }}

                                // Handle radio button selection
                              />
                              <span>{subTopic.NewSubCourseTopicName}</span>
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    </div>

  );
};

export default SubCourses;
