// csvUtils.js

/**
 * Safely escapes a value for CSV.
 */
export function csvEscape(value) {
    if (value == null) return ""; // handle null/undefined
    const str = String(value);
    if (/[",\r\n]/.test(str)) {
      return `"${str.replace(/"/g, '""')}"`;
    }
    return str;
  }
  
  /**
   * Converts an array of objects (logs) to a CSV string with headers.
   *
   * @param {Object[]} logs - The log objects to export.
   * @param {string[]} headers - An array of column headers for the CSV.
   * @param {string[]} keys - The corresponding object keys to extract from each log.
   * @returns {string} - The CSV string.
   */
  export function logsToCsv(logs, headers, keys) {
    const csvRows = [headers.join(",")]; // header row
  
    for (const log of logs) {
      const row = keys.map((key) => csvEscape(log[key]));
      csvRows.push(row.join(","));
    }
  
    return csvRows.join("\r\n");
  }
  
  /**
   * Triggers a download of a CSV file.
   *
   * @param {string} csvString - The CSV string content.
   * @param {string} filename - The name of the file to download.
   */
  export function downloadCsvFile(csvString, filename = "logs.csv", onDownloadInitiated) {
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    
    // Call the callback to notify that download has been initiated
    if (typeof onDownloadInitiated === "function") {
      onDownloadInitiated();
    }
  }
  
  