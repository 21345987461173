import React, { useState } from "react";
import LogDetailModal from "./LogModal";
import "./LogsTAble.css";

const LogsTable = ({ logs }) => {
  const [selectedLog, setSelectedLog] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  // Truncate the message to prevent very wide cells.
  // You can adjust maxLength as you wish.
  const truncateMessage = (text, maxLength = 200) => {
    if (!text) return "";
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const handleRowClick = (log) => {
    setSelectedLog(log);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedLog(null);
    setModalOpen(false);
  };

  return (
    <div className="table-container">
      <table className="fixed-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Application</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log,key) => (
            <tr style={{backgroundColor:key % 2 ? "lightGray":"white"}} key={log.Id} onClick={() => handleRowClick(log)}>
              <td>{log.Id}</td>
              <td>{log.LogDate}</td>
              <td>{log.Application}</td>
              <td>{truncateMessage(log.Message)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <LogDetailModal
        isOpen={isModalOpen}
        onClose={closeModal}
        log={selectedLog}
      />
    </div>
  );
};

export default LogsTable;
