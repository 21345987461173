import React from 'react';

const SearchInput = ({ value, onChange }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
    <label htmlFor="search-input" style={{ marginBottom: '4px' }}>
        Keyword: </label>
      <input
      style={{
        borderRadius:"8px",

        borderWidth:"1px",
        paddingLeft:"12px",
        paddingRight:"12px",
        paddingTop:"14px",
        paddingBottom:"14px"
      }}
        id="search-input"
        type="text"
        value={value}
        onChange={onChange}
        placeholder="Aramak istediğiniz sözcüğü giriniz"
      />
    </div>
  );
};

export default SearchInput;
