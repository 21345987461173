import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";

//#region Pages
import LoginPage from "./scenes/LoginPage";
import SignUpPage from "./scenes/SignUpPage";
import UserProfilePage from "./scenes/UserProfilePage";
import MembersPage from "./scenes/MembersPage";
import CreditsPage from "./scenes/Credits";
import BooksPage from "./scenes/BooksPage";
import BookSectionsPage from "./scenes/BookSectionsPage";
import BookSectionCropPage from "./scenes/BookSectionCropPage";
import WorkListPage from "./scenes/WorkListPage";
import FeedBacks from "./scenes/FeedBacksPage";
import OnlineLecturePanel from "./scenes/OnlineLecturePanel";
import AddTeacher from "./scenes/addTeachers";
import CachePage from "./scenes/Caches";
import ContentManagment from "./scenes/contentManagment"
import CoursePage from "./scenes/questionVideoSolution";
import SubTopicSelectCoursePage from "./scenes/questionSubTopicSelection/CoursePage";
import SubTopicSelection from "./scenes/questionSubTopicSelection/QuestionPage"
import AchievementCoursePage from "./scenes/questionAchievementSelection/CoursesPage"
import AchievementSelectionPage from "./scenes/questionAchievementSelection/QuestionPage"
import KpssCompatibilityCoursePage from "./scenes/kpssCompatibility/CoursesPage"
import KpssCompatibilitySelectionPage from "./scenes/kpssCompatibility/QuestionPage"
import Corporate from "./scenes/Corporate";
import AppLogs from "./scenes/AppLogs";
//#endregion

//#region Internal
import { Primary } from "./assets/Colors";

import "./index.css";
//#endregion

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <div
        style={{
          backgroundColor: Primary.Background,
          width: "100%",
          height: "100%",
        }}
      >
        <Switch>
          <Route exact path="/userprofile" component={UserProfilePage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/signup" component={SignUpPage} />
          <Route exact path="/members" component={MembersPage} />
          <Route exact path="/books" component={BooksPage} />
          <Route exact path="/booksections" component={BookSectionsPage} />
          <Route exact path="/booksectioncrop" component={BookSectionCropPage} />
          <Route exact path="/worklist" component={WorkListPage} />
          <Route exact path="/feedbacks" component={FeedBacks} />
          <Route exact path="/onlineLecturePanel" component={OnlineLecturePanel} />
          <Route exact path="/addTeachers" component={AddTeacher} />
          <Route exact path="/usercredits" component={CreditsPage} />
          <Route exact path="/caches" component={CachePage} />
          <Route exact path="/contentManagment" component={ContentManagment} />
          <Route exact path="/questionVideoSolution" component={CoursePage} />
          <Route exact path="/questionSubTopicSelectionCoursePage" component={SubTopicSelectCoursePage} />
          <Route exact path="/questionSubTopicSelection" component={SubTopicSelection} />
          <Route exact path="/AchievementCourses" component={AchievementCoursePage} />
          <Route exact path="/AchievementSelection" component={AchievementSelectionPage} />
          <Route exact path="/KpssCompatibilityCourses" component={KpssCompatibilityCoursePage} />
          <Route exact path="/KpssCompatibilitySelection" component={KpssCompatibilitySelectionPage} />
          <Route exact path="/Corporate" component={Corporate} />
          <Route exact path="/AppLogs" component={AppLogs} />
          <Route render={() => <Redirect to={{ pathname: "/login" }} />} />
        </Switch>
      </div>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
