import React, { useState } from "react";
import { logsToCsv,downloadCsvFile } from "../../../helpers/csvUtils";

const ExportCsvButton = ({ logs }) => {
  const [downloadFeedback, setDownloadFeedback] = useState(false);

  const handleExport = () => {
    // Define headers and keys for your CSV export
    const headers = ["ID", "Date", "Application", "Message"];
    const keys = ["Id", "LogDate", "Application", "Message"];
    const csvString = logsToCsv(logs, headers, keys);
    
    // Trigger the download and set feedback via a callback
    downloadCsvFile(csvString, "logs.csv", () => {
      setDownloadFeedback(true);
      // Hide feedback after 3 seconds
      setTimeout(() => setDownloadFeedback(false), 3000);
    });
  };

  return (
    <div>
      <button onClick={handleExport} style={styles.button}>
        Export as CSV
      </button>
      {downloadFeedback && (
        <div style={styles.feedback}>
          File download initiated successfully.
        </div>
      )}
    </div>
  );
};

const styles = {
  button: {
    color: "white",
    backgroundColor: "#28a745",
    padding: "8px 12px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  feedback: {
    marginTop: "10px",
    color: "green",
    fontWeight: "bold",
  },
};

export default ExportCsvButton;