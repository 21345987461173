// import React, { useState, useRef, useEffect } from 'react';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

// const RangeDatePicker = ({ onChangeDateRange }) => {
//   const today = new Date();
//   // Initialize the range with today as both start and end.
//   const [range, setRange] = useState([today, today]);
//   const [open, setOpen] = useState(false);

//   // For closing the datepicker when clicking outside.
//   const wrapperRef = useRef(null);
//   const handleClickOutside = (event) => {
//     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
//       setOpen(false);
//     }
//   };

//   useEffect(() => {
//     document.addEventListener('mousedown', handleClickOutside);
//     return () => document.removeEventListener('mousedown', handleClickOutside);
//   }, []);

//   // Called when the date range is updated
//   const handleChange = (dates) => {
//     const [start, end] = dates;
//     if (start && end) {
//       // Enforce a maximum 14-day range
//       const diffInDays = (end - start) / (1000 * 3600 * 24);
//       let adjustedEnd = end;
//       if (diffInDays > 14) {
//         adjustedEnd = new Date(start.getTime() + 14 * 24 * 3600 * 1000);
//       }
//       setRange([start, adjustedEnd]);
//       if (onChangeDateRange) {
//         onChangeDateRange({ minDate: start, maxDate: adjustedEnd });
//       }
//       setOpen(false);
//     } else {
//       setRange(dates);
//     }
//   };

//   // Quick selection buttons for common date ranges.
//   const quickSelect = (option) => {
//     let start, end;
//     switch (option) {
//       case 'today':
//         start = today;
//         end = today;
//         break;
//       case 'yesterday':
//         start = new Date(today);
//         start.setDate(start.getDate() - 1);
//         end = start;
//         break;
//       case 'last3':
//         start = new Date(today);
//         start.setDate(start.getDate() - 2);
//         end = today;
//         break;
//       case 'week':
//         start = new Date(today);
//         start.setDate(start.getDate() - 6);
//         end = today;
//         break;
//       case 'last14':
//         start = new Date(today);
//         start.setDate(start.getDate() - 13);
//         end = today;
//         break;
//       default:
//         return;
//     }
//     setRange([start, end]);
//     if (onChangeDateRange) {
//       onChangeDateRange({ minDate: start, maxDate: end });
//     }
//     setOpen(false);
//   };

//   return (
//     <div ref={wrapperRef} style={{ position: 'relative', display: 'inline-block' }}>
//       <input
//         type="text"
//         readOnly
//         placeholder="Select date range"
//         value={
//           range[0]
//             ? `${range[0].toLocaleDateString('en-GB')}${
//                 range[1] ? ` - ${range[1].toLocaleDateString('en-GB')}` : ''
//               }`
//             : ''
//         }
//         onClick={() => setOpen(!open)}
//         style={{ cursor: 'pointer' }}
//       />
//       {open && (
//         <div
//           style={{
//             position: 'absolute',
//             zIndex: 999,
//             background: '#fff',
//             border: '1px solid #ccc',
//             padding: '10px',
//           }}
//         >
//           <DatePicker
//             selected={range[0]}
//             onChange={handleChange}
//             startDate={range[0]}
//             endDate={range[1]}
//             selectsRange
//             inline
//             dateFormat="dd/MM/yyyy"
//             maxDate={today} // Disables future dates
//           />
//           <div style={{ marginTop: '10px' }}>
//             <button onClick={() => quickSelect('today')}>Today</button>
//             <button onClick={() => quickSelect('yesterday')}>Yesterday</button>
//             <button onClick={() => quickSelect('last3')}>Last 3 Days</button>
//             <button onClick={() => quickSelect('week')}>1 Week</button>
//             <button onClick={() => quickSelect('last14')}>Last 14 Days</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default RangeDatePicker;

import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const RangeDatePicker = ({ onChangeDateRange }) => {
  const today = new Date();
  // Initialize with default range set to today.
  const [range, setRange] = useState([today, today]);
  const [open, setOpen] = useState(false);
  const [helperText, setHelperText] = useState('');

  const wrapperRef = useRef(null);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Dynamically restrict end date: max = start date + 14 days (or today, whichever is earlier).
  const getDynamicMaxDate = () => {
    if (range[0]) {
      const fourteenDaysLater = new Date(range[0].getTime() + 13 * 24 * 3600 * 1000);
      return fourteenDaysLater > today ? today : fourteenDaysLater;
    }
    return today;
  };

  // Handle manual date range changes
  const handleChange = (dates) => {
    const [start, end] = dates;
    if (start && end) {
      const diffInDays = (end - start) / (1000 * 3600 * 24);
      if (diffInDays > 13) {
        // Fallback: this branch should not occur if maxDate is set properly.
        const adjustedEnd = new Date(start.getTime() + 13 * 24 * 3600 * 1000);
        setRange([start, adjustedEnd]);
        if (onChangeDateRange) {
          onChangeDateRange({ minDate: start, maxDate: adjustedEnd });
        }
        setHelperText('Maximum allowed range is 14 days. End date adjusted.');
      } else {
        setRange(dates);
        if (onChangeDateRange) {
          onChangeDateRange({ minDate: start, maxDate: end });
        }
        setHelperText('');
      }
      setOpen(false);
    } else {
      setRange(dates);
      setHelperText('Maximum allowed range is 14 days.');
    }
  };

  // Quick select options to instantly set a date range.
  const quickSelect = (option) => {
    let start, end;
    switch (option) {
      case 'today':
        start = today;
        end = today;
        break;
      case 'yesterday':
        start = new Date(today);
        start.setDate(start.getDate() - 1);
        end = start;
        break;
      case 'last3':
        start = new Date(today);
        start.setDate(start.getDate() - 2);
        end = today;
        break;
      case 'week':
        start = new Date(today);
        start.setDate(start.getDate() - 6);
        end = today;
        break;
      case 'last14':
        start = new Date(today);
        start.setDate(start.getDate() - 13);
        end = today;
        break;
      default:
        return;
    }
    // Since these quick selections are predefined within a 14-day limit,
    // we can directly update the state.
    setRange([start, end]);
    if (onChangeDateRange) {
      onChangeDateRange({ minDate: start, maxDate: end });
    }
    setHelperText('');
    setOpen(false);
  };

  return (
    <div ref={wrapperRef} style={{ position: 'relative', display: 'inline-block' }}>
      <div>
        *Tarih Aralığı
      </div>
      {/* Input displaying the selected range */}
      <input
        type="text"
        readOnly
        placeholder="Select date range"
        value={
          range[0]
            ? `${range[0].toLocaleDateString('en-GB')}${range[1] ? ` - ${range[1].toLocaleDateString('en-GB')}` : ''}`
            : ''
        }
        onClick={() => setOpen(!open)}
        style={{ cursor: 'pointer',
        borderRadius:"8px",

        borderWidth:"1px",
        paddingLeft:"12px",
        paddingRight:"12px",
        paddingTop:"14px",
        paddingBottom:"14px"
      }}
      />
      {open && (
        <div
          style={{
            position: 'absolute',
            zIndex: 999,
            background: '#fff',
            border: '1px solid #ccc',
            padding: '10px',
          }}
        >
          <DatePicker
            selected={range[0]}
            onChange={handleChange}
            startDate={range[0]}
            endDate={range[1]}
            selectsRange
            inline
            dateFormat="dd/MM/yyyy"
            maxDate={getDynamicMaxDate()}
          />
          <div style={{ marginTop: '10px' }}>
            <button onClick={() => quickSelect('today')}>Today</button>
            <button onClick={() => quickSelect('yesterday')}>Yesterday</button>
            <button onClick={() => quickSelect('last3')}>Last 3 Days</button>
            <button onClick={() => quickSelect('week')}>1 Week</button>
            <button onClick={() => quickSelect('last14')}>Last 14 Days</button>
          </div>
        </div>
      )}
      {helperText && (
        <div style={{ marginTop: '5px', color: 'red', fontSize: '0.9em' }}>
          {helperText}
        </div>
      )}
    </div>
  );
};

export default RangeDatePicker;
