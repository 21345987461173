import React from 'react';
import LogKeys from '../../../enums/LogKeys'; // Adjust the import path as needed

const LogKeysSelect = ({ selectedKey, onSelect }) => {
  const handleChange = (e) => {
    onSelect(e.target.value);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
    <label htmlFor="search-input" style={{ marginBottom: '4px' }}>
        *Key:</label>
      <select
      style={{
        borderRadius:"8px",
        height:"45px",
        borderWidth:"1px",
        paddingLeft:"12px",
        paddingRight:"12px",
        paddingTop:"14px",
        paddingBottom:"14px"
      }}
      id="logkeys-select" value={selectedKey} onChange={handleChange}>
        {!selectedKey && (
          <option value="" >
            Key seçiniz
          </option>
        )}
        {Object.entries(LogKeys).map(([key, value]) => (
          <option key={key} value={value}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LogKeysSelect;
